@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wdth,wght,YTLC@0,6..12,75..125,200..1000,440..540;1,6..12,75..125,200..1000,440..540&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "CircularStd";
  src: url("./fonts//CircularStd-Medium.ttf");
}

body,
html {
  font-family: CircularStd, sans-serif;
}
